import React from 'react'
import './SingleCard.css'

const SingleCard = ({ card, handleChoice, flipped, disabled }) => {
  const handleClick = () => {
    if (!disabled) {
      handleChoice(card)
    }
  }

  const coverBackground = '/img/cover.png'

  return (
    <div className='card'>
      <div className={flipped ? 'flipped' : ' '}>
        <div
          className='front obraz'
          style={{ backgroundImage: `url(${card.src})` }}
          alt='card front'
        ></div>
        <div
          className='back'
          style={{ backgroundImage: `url(${coverBackground})` }}
          onClick={handleClick}
          alt='card back'
        ></div>
      </div>
    </div>
  )
}

export default SingleCard
